<template>
  <div class="container bg">
    <div v-if="showlist">
      <div class="df jc-sb bg-white head">
        <div class="df">
          <div class="dfc mr50">
            <el-input v-model="minFormdata.videoName" placeholder="请输入互动话术名称"></el-input>
          </div>
          <div class="df">
            <el-button plain type="primary" @click="getlive">搜索</el-button>
            <el-button plain @click="clear">清理</el-button>
          </div>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="toAdd">添加话术</el-button>
      </div>
      <div class="box">
        <div v-if="videoList.length > 0" class="df f-w jc-sb plr20">
          <div class="listBox item" v-for="(i, idx) in videoList" :key="idx" @click="(activeInfo = i), (showlist = false)">
            <img :src="i.interaction_image" alt="" />
            <div class="info">
              <div class="tit"><span class="Ttext">互动名称：</span> {{ i.interaction_name }}</div>
              <div>
                <div class="Ttext mb5">互动关键词：</div>
                <div class="df f-w">
                  <div v-for="(j, index) in i.keywords" :key="index" class="btn">{{ j }}</div>
                </div>
              </div>
              <div class="mt5">
                <span><span class="Ttext">互动视频数量</span>{{ i.videvo_ids_num }}个</span>
                <span style="margin-left: 20px"><span class="Ttext">创建时间：</span>{{ i.create_time }}</span>
              </div>
            </div>
            <div class="tools" @click.stop="activeInfo = i">
              <el-dropdown trigger="click" placement="bottom" @command="handleClick">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="edit" icon="el-icon-edit">编辑</el-dropdown-item> -->
                  <el-dropdown-item command="del" icon="el-icon-delete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="互动话术"></myempty>
        </div>
      </div>
    </div>
    <div v-else>
      <create ref="add" :id="activeInfo.id" @back="back"></create>
    </div>
  </div>
</template>
<script>
import { get_list, del } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
import create from "./create.vue";
export default {
  components: {
    myempty,
    create,
  },
  data() {
    return {
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      showlist: true,
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      openLive: false,
      showStatus: false,
      disabled: false,
      isopen: false,
      fullscreenLoading: false,
      israndom: false,
      isAddr: false,
      isRoomId: false,
      ipt: "",
      id: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      isPlay: false,
      videoList: [],
    };
  },
  mounted() {
    this.getlive();
  },
  methods: {
    back() {
      this.showlist = true;
      this.getlive();
    },
    toAdd() {
      this.activeInfo = {};
      this.showlist = false;
      this.$refs.add.getlive();
    },
    add() {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }
      this.ipt = "";
    },
    clear() {
      this.minFormdata = {
        videoName: "",
      };
      this.getlive();
    },
    getlive() {
      let params = {
        page: 1,
        item: 1000,
        so: this.minFormdata.videoName || "",
      };
      get_list(params).then((res) => {
        if (res.code == "200") {
          // this.total = res.data.page_num;
          this.videoList = res.data.list;
          console.log(this.videoList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(command) {
      this.type = command;
      switch (command) {
        case "edit":
          this.showlist = false;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              del({
                interaction_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => {});
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}
.listBox {
  background: #fff;
  width: 49%;
  padding: 20px;
  display: flex;
  margin-bottom: 15px;
  border-radius: 8px;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    padding: 10px;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 99px;
    height: 146px;
    border-radius: 8px;
    overflow: hidden;
  }
}

// .listBox:last-child {
//   margin-bottom: 0;
// }

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

.el-select {
  width: 100%;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.item {
  .desc {
    padding: 10px;
    text-align: left;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #c8dfff;
    top: 0px;
    left: 5px;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.imgBg {
  img {
    width: 175px;
    height: 275px;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 15px;
    background: #f1f4f9;
    height: 245px;
    overflow: scroll;

    /deep/.el-button {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}
</style>
