<template>
  <div class="bg" style="height: 80vh">
    <div class="box">
      <div class="listBox">
        <div class="mb10">
          <div class="Ttext mb10">是否开启</div>
          <div style="width: 320px">
            <el-switch v-model="value" active-color="#025ee9" inactive-color="#909399"> </el-switch>
          </div>
        </div>
        <div class="mb10">
          <div class="Ttext mb10">欢迎方式</div>
          <div style="width: 320px">
            <el-radio v-model="radio" :label="1">语音助播</el-radio>
            <!-- <el-radio v-model="radio" :label="2">文字</el-radio> -->
          </div>
        </div>
        <div class="mb10">
          <div class="Ttext mb10">回复语句</div>
          <div class="df mb10" style="width: 360px" v-for="(i, idx) in keywordList" :key="idx">
            <el-input v-model="keywordList[idx]" placeholder="请输入"></el-input>
            <el-button style="margin-left: 20px" type="info" icon="el-icon-delete" @click="delKey(idx)"></el-button>
          </div>
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addKey">添加</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { edit_welcome } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    welcomeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radio: "1",
      value: true,
      keywordList: [""],
      menu: [
        {
          icon: require("@/assets/img/secondMenu/entire1.png"),
          activeicon: require("@/assets/img/secondMenu/entire.png"),
          title: "全局配置",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/welcome1.png"),
          activeicon: require("@/assets/img/secondMenu/welcome.png"),
          title: "欢迎配置",
          pageName: "shortVideo",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/upvote1.png"),
          activeicon: require("@/assets/img/secondMenu/upvote.png"),
          title: "点赞配置",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/gift1.png"),
          activeicon: require("@/assets/img/secondMenu/gift.png"),
          title: "送礼配置",
          pageName: "shortVideo",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/time1.png"),
          activeicon: require("@/assets/img/secondMenu/time.png"),
          title: "定时引导",
          pageName: "video",
          params: {},
        },
      ],
      visible: false,
      ipt: "",
      isPlay: false,
      videoList: [],
    };
  },
  watch: {
    welcomeInfo(nval) {
      if (nval != "") {
        console.log(nval);
        this.keywordList = [];
        this.value = nval.stutas == 1 ? true : false;
        this.radio = nval.types;
        this.keywordList.push(...nval.texts);
      }
    },
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit("back");
    },
    addKey() {
      this.keywordList.push("");
    },
    delKey(idx) {
      this.keywordList.splice(idx, 1);
    },
    save() {
      console.log(111);
      edit_welcome({
        live_id: this.liveId,
        stutas: this.value ? 1 : 0,
        types: this.radio,
        texts:this.keywordList
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}
</style>
