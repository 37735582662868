<template>
  <div class="bg" style="height: 80vh">
    <div class="box">
      <div class="listBox">
        <div class="mb10">
          <div class="Ttext mb10">是否开启AI回复</div>
          <div style="width: 320px">
            <el-switch v-model="value" active-color="#025ee9" inactive-color="#909399"> </el-switch>
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">助播声音</div>
          <div class="mb10" style="width: 320px">
            <el-select v-model="cat_id" placeholder="请选择音色分类" @change="sortChange">
              <el-option v-for="item in classOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
          <div style="width: 320px">
            <el-select v-model="sound_id" placeholder="请选择音色">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">助播回答间隔（秒）</div>
          <div style="width: 320px">
            <el-input v-model="sound_time" placeholder="请输入"></el-input>
          </div>
        </div>

        <!-- <div class="mb15">
          <div class="Ttext mb10">文字回复间隔（秒）</div>
          <div style="width: 320px">
            <el-input v-model="text_time" placeholder="请输入"></el-input>
          </div>
        </div> -->

        <div class="mb15">
          <div class="Ttext mb10">回复优先级</div>
          <div class="df mb10" style="width: 430px" v-for="(i, idx) in keywordList" :key="idx">
            <div class="replyItem">
              <!-- {{ keywordList[idx] }} -->
              {{ i | getText }}
            </div>
            <el-button style="margin-left: 20px; transform: rotate(180deg)" type="primary" icon="el-icon-download" @click="up(idx)"></el-button>
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">黑名单用户</div>
          <div class="df f-w">
            <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加黑名单</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myempty from "@/components/myempty.vue";
import { get_cat, gettimbre } from "@/api/audio.js";
import { edit_all } from "@/api/live.js";
export default {
  components: {
    myempty,
  },
  props: {
    entireInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    getText: (val) => {
      if (!val) return "";
      return val == "lead" ? "定时引导" : val == "welcome" ? "欢迎" : val == "gift" ? "礼物感谢" : "点赞";
    },
  },
  data() {
    return {
      sound_id: "",
      cat_id: "",
      sound_time: "",
      text_time: "",
      userName: "",
      ipt: "",
      value: false,
      inputValue: "",
      current: 0,
      showList: true,
      showAdd: false,
      inputVisible: false,
      keywordList: [],
      descList: [],
      checkList: [],
      checkaList: [],
      options: [],
      classOptions: [],
      dynamicTags: [],
    };
  },
  watch: {
    entireInfo(nval) {
      if (nval != "") {
        console.log(nval);
        this.keywordList = [];
        this.value = nval.ai_interaction == 1 ? true : false;
        this.cat_id = nval.sound_cat_id;
        this.sound_id = nval.sound_id;
        this.sound_time = nval.sound_time;
        this.text_time = nval.text_time;
        this.dynamicTags = nval.blacklist;
        this.keywordList.push(...nval.priority);
        this.sortChange(nval.sound_cat_id);
      }
    },
  },
  mounted() {
    this.get_cat();
    // this.gettimbre();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    get_cat() {
      get_cat().then((res) => {
        if (res.code == "200") {
          this.classOptions = res.data;
        }
      });
    },
    sortChange(e) {
      console.log(e, 11);
      gettimbre({ cat_id: e }).then((res) => {
        if (res.code == "200") {
          this.options = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    up(i) {
      console.log(i, 11111);
      if (i == 0) return;
      this.keywordList.splice(i - 1, 0, this.keywordList[i]);
      //删除后一项
      this.keywordList.splice(i + 1, 1);
    },
    save() {
      edit_all({
        live_id: this.liveId,
        sound_id: this.sound_id,
        sound_time: this.sound_time,
        text_time: this.text_time,
        blacklist: this.dynamicTags,
        priority: this.keywordList,
        ai_interaction: this.value ? 1 : 0,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-select > .el-input {
  width: 149% !important;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.replyItem {
  background: #f2f3f5;
  color: #939dae;
  border-radius: 6px;
  width: 320px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-button {
  padding: 12px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  padding: 15px;
  border-radius: 5px;
  height: 80vh;
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.item {
  margin-right: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  height: 315px;

  .desc {
    padding: 10px;
    text-align: left;
  }

  img {
    width: 170px;
    height: 255px;
    border-radius: 5px 5px 0 0;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: -310px;
    left: 135px;
  }
}

.item:last-child,
.item:nth-child(6n) {
  margin-right: 0;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 20px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .item {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .item:nth-child(6n) {
    margin-right: 15px;
  }
}
</style>
