<template>
  <div class="container bg">
    <el-container>
      <el-header class="bg-white" style="line-height: 60px">
        <div class="dfc">
          <div class="df">
            <div class="back" @click="back">
              <img src="~@/assets/img/back1.png" alt="" />
            </div>
            <div class="twoMenubox">
              <div class="menu" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
                <img :src="current == idx ? i.icon : i.activeicon" alt="" />
                <div>{{ i.title }}</div>
              </div>
            </div>
          </div>

          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showAdd = true">添加</el-button>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div class="vlist">
            <div v-if="current == 0">
              <div class="df f-w" v-if="evideoList.length > 0">
                <div v-for="(item, idx) in evideoList" :key="idx" @click="getItem(item)" class="imgBox">
                  <img :src="item.video_image" object-fit="cover" alt="" />
                  <div class="desc">
                    {{ item.title }}
                    <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
                  </div>
                  <div class="tools" style="color: #ff5733" @click.stop="delitem(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div v-else>
                <myempty text="视频"></myempty>
              </div>
            </div>
            <div v-if="current == 1">
              <div v-if="ivideoList.length > 0" class="mt10">
                <div class="listBox1" v-for="(i, idx) in ivideoList" :key="idx">
                  <img :src="i.interaction_image" alt="" />
                  <div class="info">
                    <div class="tit"><span class="Ttext">互动名称：</span> {{ i.interaction_name }}</div>
                    <div>
                      <div class="Ttext mb5">互动关键词：</div>
                      <div class="df f-w">
                        <div v-for="(j, index) in i.keywords" :key="index" class="btn">{{ j }}</div>
                      </div>
                    </div>
                    <div class="mt10">
                      <span><span class="Ttext">互动视频数量</span>{{ i.videvo_ids_num }}个</span>
                      <span style="margin-left: 20px"><span class="Ttext">创建时间：</span>{{ i.create_time }}</span>
                    </div>
                  </div>
                  <div class="tools" @click.stop="delitem(i)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div v-else>
                <myempty text="互动话术"></myempty>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog :title="`${current == 0 ? '添加讲解视频' : '选择互动话术'}`" :visible.sync="showAdd" width="70%" :show-close="false">
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input v-model="search" placeholder="输入关键词搜索视频" suffix-icon="el-icon-search" @blur="handleSelect" @keyup.enter.native="handleSelect"></el-input>
      </div>
      <div class="dlistBox">
        <el-checkbox-group v-model="checkList">
          <div v-if="current == 0">
            <div class="df f-w" v-if="videoList.length > 0">
              <div v-for="(item, idx) in videoList" :key="idx" @click="getItem(item)" class="imgBox">
                <img :src="item.video_image" object-fit="cover" alt="" />
                <div class="desc">
                  {{ item.title }}
                  <div><i class="el-icon-time"></i>{{ item.create_time }}</div>
                </div>
                <div class="tools" @click.stop="activeI = item">
                  <div class="check">
                    <el-checkbox :key="item.id" :label="item.id"><br /></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="视频"></myempty>
            </div>
          </div>
          <div class="info" v-else>
            <div v-if="videoList.length > 0">
              <div class="listBox1" v-for="(i, idx) in videoList" :key="idx">
                <div class="check mr10">
                  <el-checkbox :key="i.id" :label="i.id"><br /></el-checkbox>
                </div>
                <img :src="i.interaction_image" alt="" />
                <div class="info">
                  <div class="tit"><span class="Ttext">互动名称：</span> {{ i.interaction_name }}</div>
                  <div>
                    <div class="Ttext mb5">互动关键词：</div>
                    <div class="df f-w">
                      <div v-for="(j, index) in i.keywords" :key="index" class="btn">{{ j }}</div>
                    </div>
                  </div>
                  <div class="mt5">
                    <span><span class="Ttext">互动视频数量</span>{{ i.videvo_ids_num }}个</span>
                    <span style="margin-left: 20px"><span class="Ttext">创建时间：</span>{{ i.create_time }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="互动话术"></myempty>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button type="primary" @click="additem">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { goods_query_data, goods_del_data, goods_add_data, get_list } from "@/api/live.js";
import { getVideo } from "@/api/video.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        name: "",
        videoName: "",
      },
      showAdd: false,
      current: 0,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/video1.png"),
          activeicon: require("@/assets/img/secondMenu/video.png"),
          title: "直播视频",
          pageName: "video",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/hudong1.png"),
          activeicon: require("@/assets/img/secondMenu/hudong.png"),
          title: "互动话术",
          pageName: "shortVideo",
          params: {},
        },
      ],
      search: "",
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      isPlay: false,
      checkList: [],
      videoList: [], //视频列表
      evideoList: [], //商品视频
      ivideoList: [], //直播互动
      interactionArr: [], //互动话术列表
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getList();
      this.getVideo();
    },
    back() {
      this.$emit("back");
    },
    handleSelect() {
      console.log(this.ipt);
    },
    menuHandle(e, idx) {
      this.current = e ? idx : 0;
      this.getList(idx);
      this.getVideo();
    },
    getList() {
      goods_query_data({
        page: 1,
        item: 1000,
        goods_id: this.info.id,
        type: this.current + 1,
      }).then((res) => {
        if (res.code == "200") {
          if (this.current == 0) {
            this.evideoList = res.data.list;
          } else {
            this.ivideoList = res.data.list;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getVideo() {
      if (this.current == 0) {
        getVideo({
          page: 1,
          item: 1000,
          statusa: 3,
          type_s: 2,
        }).then((res) => {
          if (res.code == "200") {
            this.videoList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        get_list({
          page: 1,
          item: 1000,
        }).then((res) => {
          if (res.code == "200") {
            this.videoList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    delitem(i) {
      this.$confirm("是否删除该视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          goods_del_data({
            goods_id: this.info.id,
            type: this.current + 1,
            data_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    additem() {
      let arr = [];
      this.checkList.map((i) => {
        arr.push(i + "");
      });
      console.log(arr);
      goods_add_data({
        goods_id: this.info.id,
        type: this.current + 1,
        data: arr,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getList();
          this.checkList = [];
          this.showAdd = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.twoMenubox {
  display: flex;
  justify-content: space-between;
  padding-top: 0;

  .menu {
    display: flex;
    align-items: center;
    background: #f2f7fe;
    color: #8b9ab4;
    padding: 10px 8px;
    margin: 8px 10px;
    border-radius: 6px;
    justify-content: space-around;
    width: 130px;
    height: 45px;

    img {
      margin-right: -8px;
      width: 16px;
      height: 18px;
    }
  }

  .active {
    color: #fff !important;
    background: #015ce6;
    margin: 8px 10px;
    border-radius: 6px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.vlist {
  .info {
    padding-top: 10px;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 10px;
    background: #fff;
  }

  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 5px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .imgBox {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .imgBox:nth-child(5n) {
    margin-right: 0;
  }
  .imgBox:nth-child(6n) {
    margin-right: 10px;
  }
}
</style>
