<template>
  <div class="bg" style="height: 80vh">
    <div class="box">
      <div class="listBox">
        <div class="mb10">
          <div class="Ttext mb10">是否开启</div>
          <div style="width: 320px">
            <el-switch v-model="form.stutas" active-color="#025ee9" inactive-color="#909399"> </el-switch>
          </div>
        </div>

        <div class="mb10">
          <div class="Ttext mb10">欢迎方式</div>
          <div style="width: 320px">
            <el-radio v-model="form.types" :label="1">语音助播</el-radio>
            <!-- <el-radio v-model="form.types" :label="2">文字</el-radio> -->
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">引导间隔（秒）</div>
          <div style="width: 320px">
            <el-input v-model="form.lead_times" placeholder="请输入"></el-input>
          </div>
        </div>

        <div class="mb15">
          <el-row :gutter="20">
            <el-col :span="5">
              <div class="Ttext mb10">综合引导概率（%）</div>
              <el-input v-model="form.chance.count" placeholder="请输入"></el-input>
            </el-col>
            <el-col :span="5">
              <div class="Ttext mb10">关注引导概率（%）</div>
              <el-input v-model="form.chance.share" placeholder="请输入"></el-input>
            </el-col>
            <el-col :span="5">
              <div class="Ttext mb10">消费引导概率（%）</div>
              <el-input v-model="form.chance.concern" placeholder="请输入"></el-input>
            </el-col>
            <el-col :span="5">
              <div class="Ttext mb10">分享引导概率（%）</div>
              <el-input v-model="form.chance.shopping" placeholder="请输入"></el-input>
            </el-col>
          </el-row>
        </div>

        <div class="mb10">
          <div class="Ttext mb10">播放顺序</div>
          <div style="width: 320px">
            <el-radio v-model="form.radio_order" :label="1">随机</el-radio>
            <el-radio v-model="form.radio_order" :label="2">顺序</el-radio>
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">引导内容</div>
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="Ttext mb10 tbox t1">综合引导</div>
              <div class="df mb10" style="width: 250px" v-for="(i, idx) in form.lead_text.count" :key="idx">
                <el-input v-model="form.lead_text.count[idx]" placeholder="请输入"></el-input>
                <el-button style="margin-left: 15px" type="info" icon="el-icon-delete" @click="delKey(idx, form.lead_text.count)"></el-button>
              </div>
              <el-button type="info" icon="el-icon-circle-plus-outline" @click="addKey(form.lead_text.count)">添加</el-button>
            </el-col>
            <el-col :span="6">
              <div class="Ttext mb10 tbox t2">关注引导</div>
              <div class="df mb10" style="width: 250px" v-for="(i, idx) in form.lead_text.concern" :key="idx">
                <el-input v-model="form.lead_text.concern[idx]" placeholder="请输入"></el-input>
                <el-button style="margin-left: 15px" type="info" icon="el-icon-delete" @click="delKey(idx, form.lead_text.concern)"></el-button>
              </div>
              <el-button type="info" icon="el-icon-circle-plus-outline" @click="addKey(form.lead_text.concern)">添加</el-button>
            </el-col>
            <el-col :span="6">
              <div class="Ttext mb10 tbox t3">消费引导</div>
              <div class="df mb10" style="width: 250px" v-for="(i, idx) in form.lead_text.shopping" :key="idx">
                <el-input v-model="form.lead_text.shopping[idx]" placeholder="请输入"></el-input>
                <el-button style="margin-left: 15px" type="info" icon="el-icon-delete" @click="delKey(idx, form.lead_text.shopping)"></el-button>
              </div>
              <el-button type="info" icon="el-icon-circle-plus-outline" @click="addKey(form.lead_text.shopping)">添加</el-button>
            </el-col>
            <el-col :span="6">
              <div class="Ttext mb10 tbox t4">分享引导</div>
              <div class="df mb10" style="width: 250px" v-for="(i, idx) in form.lead_text.share" :key="idx">
                <el-input v-model="form.lead_text.share[idx]" placeholder="请输入"></el-input>
                <el-button style="margin-left: 15px" type="info" icon="el-icon-delete" @click="delKey(idx, form.lead_text.share)"></el-button>
              </div>
              <el-button type="info" icon="el-icon-circle-plus-outline" @click="addKey(form.lead_text.share)">添加</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myempty from "@/components/myempty.vue";
import { edit_lead } from "@/api/live.js";
export default {
  components: {
    myempty,
  },
  props: {
    leadInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        lead_times: "",
        textTime: "",
        types: 1,
        radio_order: 1,
        stutas: 1,
        chance: {
          count: "", //count综合引导概率
          share: "", //share分享引导概率
          concern: "", //concern关注引导概率
          shopping: "", //shopping消费引导概率
        },
        lead_text: {
          count: [""], //综合文案组
          share: [""], //分享文案组
          concern: [""], //关注文案组
          shopping: [""], //消费文案组
        }, //文案组
      },
    };
  },
  watch: {
    leadInfo(nval) {
      if (nval != "") {
        Object.assign(this.form, nval);
        this.form.stutas = this.form.stutas == 1 ? true : false;
        console.log(this.form);
      }
    },
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("back");
    },
    save() {
      edit_lead({
        live_id: this.liveId,
        ...this.form,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addKey(arr) {
      arr.push("");
    },
    delKey(idx, arr) {
      arr.splice(idx, 1);
    },
  },
};
</script>
<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.tbox {
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
}
.t1 {
  background: #025ee9;
}
.t2 {
  background: #00baad;
}
.t3 {
  background: #ff8d1a;
}
.t4 {
  background: #ffc300;
}

.replyItem {
  background: #f2f3f5;
  color: #939dae;
  border-radius: 6px;
  width: 320px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-button {
  padding: 12px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  padding: 15px;
  border-radius: 5px;
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.item {
  margin-right: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  height: 315px;

  .desc {
    padding: 10px;
    text-align: left;
  }

  img {
    width: 170px;
    height: 255px;
    border-radius: 5px 5px 0 0;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: -310px;
    left: 135px;
  }
}

.item:last-child,
.item:nth-child(6n) {
  margin-right: 0;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 20px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .item {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .item:nth-child(6n) {
    margin-right: 15px;
  }
}
</style>
